<template>
  <div class="row avatar-modal mb-2">
      <div class="avatar-modes-container">
        <div class="avatar-modes-item" @click="currentAvatarGroup = 'small'">
          <img :src="require('@/assets/images/avatar/peq_girl.png')"
            :class="[currentAvatarGroup == 'small' ? 'current_avatar' : 'avatar']">
          Small
        </div>
        <div class="avatar-modes-item" @click="currentAvatarGroup = 'big'">
          <img :src="require('@/assets/images/avatar/grand_girl.png')"
            :class="[currentAvatarGroup == 'big' ? 'current_avatar' : 'avatar']">
         Big
        </div>
      </div>
      <div class="avatar-container">
         <div 
            class="avatar-item" 
            @click="setAvatar(57)"
            >
              <img :src="require('@/assets/images/avatar/AvatarSprite_' + 57 + '.png?v4')"
                :class="[currentAvatar == 57 ? 'current_avatar' : 'avatar']">
          </div>
          <div v-for="index in first_range" :key="index"
            class="avatar-item" 
            @click="setAvatar(index)"
            >
              <img :src="require('@/assets/images/avatar/AvatarSprite_' + index + '.png?v4')"
                :class="[currentAvatar == index ? 'current_avatar' : 'avatar']">
          </div>
          <div v-for="index in second_range" :key="index"
            class="avatar-item" 
            @click="setAvatar(index)"
            >
              <img :src="require('@/assets/images/avatar/AvatarSprite_' + index + '.png?v4')"
                :class="[currentAvatar == index ? 'current_avatar' : 'avatar']">
          </div>
      </div>

  </div>
</template>

<script>
export default {
    data() {
      let small_range_1 = []
      for (let index = 1; index < 25; index++) {
        small_range_1.push(index)
      }
      let small_range_2 = []
      for (let index = 58; index < 81; index++) {
        small_range_2.push(index)
      }
      let big_range_1 = []
      for (let index = 25; index < 57; index++) {
        big_range_1.push(index)
      }
      let big_range_2 = []
      for (let index = 81; index <= 133; index++) {
        big_range_2.push(index)
      }
      
        return {
            small_range_1,
            small_range_2,
            big_range_1,
            big_range_2,
            avatars: [],
            currentAvatar: this.avatar_id,
            currentAvatarGroup: 'small'
        }
    },
    props: ['avatar_id', 'avatar_count'],
    methods: {
      setAvatar(id){
        this.currentAvatar = id;
        this.$emit('set-avatar', id);
      }
    },
    computed:{
      first_range(){
        if(this.currentAvatarGroup=='small'){
          return this.small_range_1
        } else {
          return this.big_range_1
        }
      },
      second_range(){
        if(this.currentAvatarGroup=='small'){
          return this.small_range_2
        } else {
          return this.big_range_2
        }
      },
    }
}
</script>

<style lang="scss" scoped>

.avatar-modal{
            background-color: rgb(245, 245, 245);
            border-radius: 8px;
            padding: 20px;
            text-align: center;
            h4{
                font-size: 18px;
                margin-top: 10px;
                margin-bottom: 20px;
            }
            .avatar-container{
                max-height: 300px;
                overflow-y: scroll;
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: stretch;
                align-content: flex-start;
                gap: 10px;
            }
            .avatar-modes-container{
                max-height: 100px;
                overflow-y: scroll;
                margin: 25px 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items:center;
                align-content:center;
                gap: 10px;
                width: -webkit-fill-available;

                .avatar-modes-item{
                    flex-grow: 1;
                    max-width: 94px;
                    max-height: 100px;
                    overflow: hidden;
                    img{
                        max-width: 70px;
                        max-height: 70px;
                    }
                    .current_avatar{
                        border-radius: 50%;
                        border: 5px solid #e27d02;
                        background-color: orange;
                        &:hover{
                            background-color: #ff8c00;
                            cursor: pointer;
                        }
                    }
                    .avatar{
                        /* background-color: #09b8dd;
                        border: 5px solid #0193b1; */
                        border-radius: 50%;
                        &:hover{
                            /* background-color: #02a3c4; */
                            cursor: pointer;
                        }
                    }
                }
            }
            .avatar-item{
                flex-grow: 1;
                max-width: 100px;
                max-height: 100px;
                overflow: hidden;
                img{
                    max-width: 100px;
                    max-height: 100px;
                }
                .current_avatar{
                    border-radius: 50%;
                    border: 5px solid #e27d02;
                    background-color: orange;
                    &:hover{
                        background-color: #ff8c00;
                        cursor: pointer;
                    }
                }
                .avatar{
                    background-color: #09b8dd;
                    border: 5px solid #0193b1;
                    border-radius: 50%;
                    &:hover{
                        background-color: #02a3c4;
                        cursor: pointer;
                    }
                }
            }
            .close-avatar-modal-button{
                cursor: pointer;
                position: relative;
                top:-30px;
                float: right;
                background-color: white;
                color: red;
                font-size: 25px;
                &:hover {
                    background-color: red;
                    color: white;
                }
                
                border-radius: 50px;
                height: 40px;
                width: 40px;
                z-index: 3;
                box-shadow: 3px 3px 15px #0000007d;
                i{
                    margin-top: 7px;  
                }
            }
        }
</style>