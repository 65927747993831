<template>
  <div>

    <validation-observer
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row class="mb-2">
          <b-col>
            <b-avatar
              size="70px"
              class="mr-2"
              :src="require('@/assets/images/avatar/AvatarSprite_' + (userData.avatar_id || 57) + '.png?v4')"

            />
            <b-button
              :variant="!showAvatarOptions?'primary':'secondary'"
              @click="showAvatarOptions = !showAvatarOptions"
            >
              {{ showAvatarOptions ? $t('table.hide') : $t('table.change')}}
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="showAvatarOptions">
          <b-col>
            <avatar-modal-selector
                :avatar_id="userData.avatar_id"
                @set-avatar="setAvatar"
                @toggle-edit-avatar="showAvatarOptions = false"
            ></avatar-modal-selector>
          </b-col>
        </b-row>
        <b-row>

          <!-- Full Name -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.name')"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"

            >
              <b-form-group
                :label="$t('fields.name')"
                label-for="dreamer-name"
              >
                <b-form-input
                  id="dreamer-name"
                  v-model="userData.dreamer_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="John Doe"
                  maxlength = "30"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <!-- Field: Group -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.group')"
              rules="required"
              :custom-messages="{ required: $t('validators.required') }"

            >
              <b-form-group
                :label="$t('fields.group')"
                label-for="dreamer-group"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.dreamer_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="groupOptions"
                  :selectable="val => val.can_select"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="dreamer-group"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      type="button"
      @click="submitChanges"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="button"
      @click="hasHistory() 
        ? $router.go(-1) 
        : $router.push({ 
          name: 'students-view', 
          params: {
                      id: school.id,
                      student_id: userData.id,
                    },
        })"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('forms.go_back') }}
    </b-button>
  </div>
</template>

<script>
import { editLicence } from '@/api/routes'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import AvatarModalSelector from './AvatarModalSelector.vue'
export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,

    AvatarModalSelector,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    school: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true,
    },
    groupOptions: {
      type: Array,
      required: true,
    },
  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
  },
  data(){
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return {
      required,
      alphaNum,
      password,

      minDate: tomorrow,

      showAvatarOptions:false,

    }
  },
  methods: {
    hasHistory () { 
       return window.history.length > 2 
    },
    //AVATAR
    setAvatar(id){
        this.userData.avatar_id = id;
        this.showAvatarOptions = false
    },
    async submitChanges(){
      let data = {
          'tab': 'dreamer',
          'user_id': this.userData.id,
          'name':this.userData.name,
          'email':this.userData.email,
          'max_children':this.userData.max_children,
          'lang':this.userData.lang,
          'role_id':this.userData.role_id
        }
      await this.$http.post(editLicence, data).then( response => {
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
